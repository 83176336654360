<style scoped>
.menu-logo {
  float: left;
  font-weight: 500;
  margin-left: 5em;
  font-size: 1.3em;
  line-height: 60px;
}

.menu-nav {
  /*width: 420px;*/
  /*margin: 0 auto;*/
  /*margin-right: 20px;*/
  float: right;
}

.ivu-menua {
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
}

.header {
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
  background: #fff;
  height: 60px;

  border-bottom: 1px solid #dddee1
}

.ivu-menu-item {
  /*padding: 0 10px;*/
}

</style>

<template>
  <div id="app">
    <Layout>

      <div class="header">
        <div class="menu-logo">消逝的红叶</div>
        <div class="menu-nav">
          <Menu mode="horizontal" theme="light" :active-name="activeMenu" @on-select="selectMenu"
                v-if="windowWidth >= 870">
            <MenuItem name="/index">
              <Icon type="ios-navigate"></Icon>
              主页
            </MenuItem>
            <MenuItem name="/life">
              <Icon type="ios-keypad"></Icon>
              旧梦沧桑
            </MenuItem>
            <MenuItem name="/ting">
              <Icon type="md-radio"></Icon>
              Ting
            </MenuItem>
            <!-- <MenuItem name="/work"><Icon type="ios-bowtie"></Icon>作品</MenuItem> -->
            <MenuItem name="/guestbook">
              <Icon type="ios-bookmarks"></Icon>
              留言板
            </MenuItem>
            <MenuItem name="/about">
              <Icon type="md-person"></Icon>
              关于我
            </MenuItem>
          </Menu>

          <Dropdown trigger="custom" style="margin: 17px 20px 0 0;" :visible="dropdownVisible" v-if="windowWidth < 870">
            <!-- <Button type="primary">下拉菜单<Icon type="arrow-down-b"></Icon></Button> -->
            <a href="javascript:void(0)" @click="selectMiniMenu">
              <Icon type="md-menu" size="30"/>
            </a>
            <Menu slot="list" mode="vertical" theme="light" :active-name="activeMenu" @on-select="selectMenu">
              <MenuItem name="/index">
                <Icon type="ios-navigate"></Icon>
                主页
              </MenuItem>
              <MenuItem name="/life">
                <Icon type="ios-keypad"></Icon>
                旧梦沧桑
              </MenuItem>
              <MenuItem name="/ting">
                <Icon type="md-radio"></Icon>
                Ting
              </MenuItem>
              <!-- <MenuItem name="/work"><Icon type="ios-bowtie"></Icon>作品</MenuItem> -->
              <MenuItem name="/guestbook">
                <Icon type="ios-bookmarks"></Icon>
                留言板
              </MenuItem>
              <MenuItem name="/about">
                <Icon type="md-person"></Icon>
                关于我
              </MenuItem>
            </Menu>
          </Dropdown>

        </div>

      </div>

      <div style="margin-top: 50px;">
        <router-view/>
      </div>

      <Footer style="text-align: center;">
        <p>CopyRight ©2012-2021 消逝的红叶·版权所有 Inc.</p>
        <p><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备14031707号</a></p>
      </Footer>
      <BackTop></BackTop>
    </Layout>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      activeMenu: '',
      windowWidth: 1000,
      dropdownVisible: false//控制手机菜单的展开
    }
  },
  created() {
    this.activeMenu = this.$route.name;
  },
  watch: {// 路由控制菜单选中状态
    '$route'(to) {
      this.activeMenu = to.name;
    },
  },
  methods: {
    selectMenu(name) {
      this.$router.push(name);
      this.dropdownVisible = false;
    },
    selectMiniMenu() {
      this.dropdownVisible = !this.dropdownVisible;
    },

  },
  mounted() {
    this.windowWidth = window.innerWidth;
  }
}
</script>
