import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {path: '/',name: '/index',component: (resolve) => {require(['./views/index'], resolve)}},
  {path: '/index',name: '/index',component: (resolve) => {require(['./views/index'], resolve)}},
  {path: '/about',name: '/about',component: (resolve) => {require(['./views/about'], resolve)}},
  {path: '/guestbook',name: '/guestbook',component: (resolve) => {require(['./views/guestbook'], resolve)}},
  {path: '/life',name: '/life',component: (resolve) => {require(['./views/life'], resolve)}},
  {path: '/ting',name: '/ting',component: (resolve) => {require(['./views/ting'], resolve)}},
  {path: '/login',name: '/login',component: (resolve) => {require(['./views/login'], resolve)}},
  {path: '/*',name: '/404',component: (resolve) => {require(['./views/404'], resolve)}}
];

const router = new Router({
  mode: 'history',
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.requireAuth)) {
    if (window.localStorage.getItem('token')) {
      next();
    } else {
      next({path: '/login'})
    }
  } else {
    next();
  }
})

export default router
