import Vue from 'vue'
import App from './App.vue'
import router from './router';


import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

import axios from 'axios';
axios.defaults.baseURL="/api";

// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (window.localStorage.getItem('token')) {
            config.headers.Token = window.localStorage.getItem('token');
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    window.localStorage.setItem('token','');
                    router.replace({
                        path: '/login',
                    });
            }
        }
        // console.log(JSON.stringify(error));
        //console : Error: Request failed with status code 402
        return Promise.reject(error.response.msg);
    }
);

Vue.$http=Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
